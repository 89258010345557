<template>
  <div role="application" class="nontest" data-section="true">
    <div class="box1">
      <h1 class="title hasicon"><img src="@assets/images/main/sound.png" width="48" height="48" alt=""/>Test sound</h1>
      <div class="content">
        <p>Put on your headphones and click on the <em>Play sound</em> button to play a sample sound.</p>
        <audio id="sample-audio-content" loop>
          <source src="@assets/sound/sample-audio.ogg" type="audio/ogg">
          Your browser does not support the audio element.
        </audio>
        <div class="textCentre">
          <input type="button" :value="isPlay ? 'Stop sound' : 'Play sound'" @click="() => isPlay = !isPlay" class="button sound"/>
        </div>
        <p class="attention">If you cannot hear the sound clearly, please tell the invigilator.</p>
        <div class="textCentre">
          <input type="submit" value="Continue" class="button" data-confirm="3" @click="() => $emit('TestSoundDone')"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {bus} from "@/main";

export default {
  name: "TestSound",
  data () {
    return {
      isPlay: false
    }
  },
  watch: {
    isPlay (nVal, oVal) {
      const audio = document.getElementById('sample-audio-content')
      this.$store.state["app/isTestEnded"] = nVal
      if (nVal) {
        audio.volume = this.$store.getters["app/volume"]
        audio.play()

      } else {
        audio.pause()
        audio.currentTime = 0;
      }
    }
  },
  mounted() {
    bus.$on('changeVolume', this.changeVolume)
  },
  methods: {
    changeVolume () {
      const audio = document.getElementById('sample-audio-content')
      audio.volume = this.$store.getters["app/volume"]
    }
  }
}
</script>

<style scoped>

</style>
