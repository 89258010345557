<template>
  <div role="main" style="height: calc(100vh - 105px)">
    <TestSound v-if="!testSoundDone" @TestSoundDone="() => testSoundDone = true" />
    <Instructions v-else />
  </div>
</template>

<script>
import TestSound from "@/views/apps/academic-listening/components/TestSound";
import Instructions from "@/views/apps/academic-listening/components/Instructions";

export default {
  name: "ListeningInstruction",
  components: {Instructions, TestSound},
  data () {
    return {
      testSoundDone: false
    }
  }
}
</script>

<style scoped>

</style>
