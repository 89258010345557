<template>
  <div id="itemBody" class="itemBody"
       data-section="true">
    <div class="container">
      <div class="instructions">
        <h1>IELTS Listening</h1>
        <div class="preTestInfo">
          <p>Time: Approximately 30 minutes</p>
          <h2>INSTRUCTIONS TO CANDIDATES</h2>
          <ul>
            <li>Answer <b>all</b> the questions.</li>
            <li>You can change your answers at any time during the test.</li>
          </ul>
          <h2>INFORMATION FOR CANDIDATES</h2>
          <ul>
            <li>There are 40 questions in this test.</li>
            <li>Each question carries one mark.</li>
            <li>There are four parts to the test.</li>
            <li>You will hear each part once.</li>
            <li>For each part of the test there will be time for you to look through the questions and time for you to
              check your answers.
            </li>
          </ul>
        </div>
        <div class="textCentre">
          <p class="info central">Do not click 'Start test' until you are told to do so.</p>
          <input type="submit" id="instructionsConfirm" value="Start test" class="button green" @click="$router.push({name: 'apps-academic-listening-main'})"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Instructions"
}
</script>

<style scoped>

</style>
